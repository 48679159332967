<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <logo />
      <h2 class="brand-text text-primary ml-2">
        {{ $t('Global.Name') }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('Error.NotFound.Title') }}
        </h2>
        <p class="mb-2">
          {{ $t('Error.NotFound.Message') }}
        </p>

        <b-button variant="primary" class="mb-2 btn-sm-block" @click="redirect">
          {{ $t('Error.NotFound.BackToHome') }}
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="require('@/assets/images/pages/error.svg')"
          alt="Error page"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
import { BLink, BButton, BImg } from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    Logo,
    BLink,
    BButton,
    BImg,
  },
  methods: {
    redirect() {
      this.$router.push({ name: isUserLoggedIn() ? 'home' : 'login' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
